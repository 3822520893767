import React from "react";
import "./styles.scss";
import PlacementProcess from "./PlacementProcess";

const Certification = () => {
  return (
    <div className="career-prep ">
      <div className="career-title">80+ hours of career prep</div>
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png"
        loading="lazy"
        className="c-p-underline"
      />
      <img
        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum832f0yk.webp"
        loading="lazy"
        className="careep-prep-img mobile-none"
      />
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/e9d233fb-9c08-4517-8357-587282525862/events/banner-upload/14i86yo8tqm836pjl0.webp"
        loading="lazy"
        className="careep-prep-img pc-none"
      />
      <PlacementProcess />
    </div>
  );
};

export default Certification;
