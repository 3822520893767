import React, { useState, useEffect } from "react";
import { eligibilty } from "../CareerPrep/eligibility";
import { mobileSliderData, admission } from "./data";

import "./styless.scss";

const AdmissionAndCriteria = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [finalSlide, setfinalSlide] = useState(false);
  const handlePrev = () => {
    if (currentSlide > 0) {
      if (window.innerWidth > 800) setCurrentSlide(-1);
      else setCurrentSlide(currentSlide - 1);

      setfinalSlide(false);
    }
  };

  const handleNext = () => {
    const maxSlides = 4 - (window.innerWidth <= 800 ? 1 : 3);

    if (finalSlide) {
      return;
    }

    if (currentSlide < maxSlides) {
      if (currentSlide === -1) {
        setCurrentSlide(currentSlide + 2);

        if (window.innerWidth > 800) setfinalSlide(true);

        return;
      }

      setCurrentSlide(currentSlide + 1);

      if (!(currentSlide + 1 < maxSlides)) {
        setfinalSlide(true);
      } else setfinalSlide(false);
    }
  };

  useEffect(() => {
    const cardContainer = document.querySelector(
      ".admission-process-basl-slider-mob "
    );
    const slideWidth = window.innerWidth <= 800 ? 280 : 300 * 3 + 2 * 3; // Adjust slide width based on screen size

    cardContainer.scrollLeft = currentSlide * slideWidth;
  }, [currentSlide]);

  return (
    <div className="admission-and-criteria">
      <div className="career-title mtop-3 section" id="admission">
        Admission Process
      </div>
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png"
        loading="lazy"
        className="placement-underline"
      />
      <p className="text-mob">
        4-step process which takes up 7-10 days from <br /> application to final
        decision
      </p>
      <div className="basl-admission-cards mob-hide">
        {admission &&
          admission.map((admission, index) => {
            return (
              <>
                {/* <img src={admission.dotBg} className="basl-admission-dot" /> */}
                <div
                  key={index}
                  className="basl-admission-card"
                  style={{ background: `url(${admission.bg})` }}
                >
                  {/* <img src={admission.dotBg} className="basl-admission-dot" /> */}
                  <div>
                    <div className="basl-admission-title">
                      {admission.title}
                    </div>
                    <div className="basl-admission-desc">{admission.des}</div>
                  </div>
                  {/* Render dot only if it's not the last card */}
                  {index > 0 && index !== admission.length - 1 && (
                    <img src={admission.dotBg} className="basl-admission-dot" />
                  )}
                </div>
              </>
            );
          })}
      </div>

      <div className="admission-process-basl-slider-mob pc-hide">
        <img
          className="adm-bgdot-basl"
          src={
            "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46ok1sy.webp"
          }
        />

        {mobileSliderData &&
          mobileSliderData.map((info, index) => (
            <div
              className={`admission-card-slider basl-mob-admission-card-slider-${index}`}
              key={index}
              // style={{ background: `url(${info.mobBg})` }}
            >
              <div className="basl-admission-title">{info.titlemob}</div>
              <div className="basl-admission-desc">{info.desmob}</div>
            </div>
          ))}
      </div>
      <div className="adm-btn-container">
        <button
          className="adm-prevBtn"
          style={{
            backgroundImage:
              "url('https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6ao5kb5.png')",
          }}
          onClick={handlePrev}
        ></button>
        <button
          className="adm-nxtBtn"
          style={{
            backgroundImage:
              "url('https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6ao77j1.png')",
          }}
          onClick={handleNext}
        ></button>
      </div>
      {/* <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ueb1do.png"
        loading="lazy"
        className="AdmissionImg-prep-img pc-hide"
      /> */}

      <div className="career-title mtop-3">Eligibility criteria</div>
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png"
        loading="lazy"
        className="placement-underline"
      />
      <div className="basl-eligibility-cards">
        {eligibilty &&
          eligibilty.map((eligibility, index) => {
            return (
              <div key={index} className="basl-eligibility-card">
                <img
                  src={eligibility.img}
                  className={`basl-eligi-icon basl-eligi-icon-${index}`}
                  loading="lazy"
                />
                <div>
                  <div className="basl-eligibility-head">
                    {eligibility.head}
                  </div>
                  <div className="basl-eligibility-desc">
                    {eligibility.desc}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AdmissionAndCriteria;
