export const cardData = [
  {
    term: 1,
    index: 1,
    head: "Case-Based Learning",
    subhead: "Strategic Business Pillars",
    bg: "#FBE7B9",
    desc: [
      "Commercial functions",
      "KPI and Value Chain Understanding",
      "P&L Statement",
      "Cash Flows, Ratios and ROI",
    ],
  },
  {
    term: 1,
    index: 2,
    bg: "#DFF4F8",
    head: "Projects",
    desc: ["Business Challenge", "Business Case Showcase"],
  },
  {
    term: 1,
    index: 3,
    head: "Workshops",
    subhead: "Better Problem Solving (BPS)",
    bg: "#F4D0C3",
    desc: ["BPS Mindset  Building", "DIG DEEP Application"],
    subheadSecond: "Digital and AI Literacy",
    descSecond: ["Intel Gathering & Data Enrichment"],
    subheadThird: "Human Skills",
    descThird: ["Building your profile and narrative"],
  },
  {
    term: 1,
    index: 4,
    head: "Evals",
    subhead: "Interview and Presentations",
    bg: "#DFF4F8",
    desc: ["Baseline Assessment on Voice and Accent"],
  },
  {
    term: 2,
    index: 1,
    head: "Case-Based Learning",
    subhead: "Sales & Persuasion",
    bg: "#FBE7B9",
    desc: [
      "Building Trust",
      "Intelligence Gathering",
      "Probing",
      "Finding Value Prop",
      "Objection Handling",
    ],
  },
  {
    term: 2,
    index: 2,
    head: "Projects",
    bg: "#DFF4F8",
    desc: ["Sales and Persuasion Project", "Sales Process Showcase"],
    // disc: "* Companies change every batch",
  },
  {
    term: 2,
    index: 3,
    bg: "#F4D0C3",
    head: "Workshops",
    subhead: "Better Problem Solving (BPS)",
    desc: ["Excel as an Analysis Tool"],
    subheadSecond: "Digital and AI Literacy",
    descSecond: ["Outbound and Calling Tools", "Using and Leveraging CRMs"],
    subheadThird: "Human Skills",
    descThird: ["CV and Linkedin Optimization", "Voice and Accent Training"],
  },
  {
    term: 2,
    index: 4,
    bg: "#DFF4F8",
    head: "Evals",
    subhead: "Interview and Presentations",
    desc: ["Core Sales and Persuasion Presentation", "Roadmap on Improvements"],
  },
  {
    term: 3,
    index: 1,
    bg: "#FBE7B9",
    head: "Case-Based Learning",
    subhead: "B2B Sales",
    desc: [
      "B2B sales cycles, Revenue & Pricing models",
      "Building ICPs & User Personas",
      "Prospecting & Qualification",
      "Building Cadences",
      "Demos, Account Management",
    ],
  },
  {
    term: 3,
    index: 2,
    bg: "#DFF4F8",
    head: "Projects",
    desc: ["B2B Sales Project - Driving Revenue", "B2B Showcase"],
    // disc: "* Companies change every batch ",
  },
  {
    term: 3,
    index: 3,
    bg: "#F4D0C3",
    head: "Workshops",
    subhead: "Better Problem Solving (BPS)",
    desc: [
      "Transferrable Consulting Frameworks",
      "Excel as an Analysis Tool",
      "BPS on your Projects",
    ],
    subheadSecond: "Digital and AI Literacy",
    descSecond: ["Automating Workflows", "Building a Tech Mindset"],
    subheadThird: "Human Skills",
    descThird: ["Voice and Accent Training", "Building a Strong Work Ethic"],
  },
  {
    term: 3,
    index: 4,
    bg: "#DFF4F8",
    head: "Evals",
    subhead: "Interview and Presentations",
    desc: ["B2B Mock Interview", "Roadmap on Improvements"],
  },
  {
    term: 4,
    index: 1,
    bg: "#FBE7B9",
    head: "Case-Based Learning",
    subhead: "FMCG Sales",
    desc: [
      "FMCG Sales Levers, KPIs,  Stakeholders",
      "Channel Management",
      "Sales Levers & Margins",
      "Inventory & Pipeline Management",
      "Ecom  and Category Management",
    ],
  },
  {
    term: 4,
    index: 2,
    bg: "#DFF4F8",
    head: "Projects",
    desc: ["FMCG Sales Project", "FMCG Showcase"],
    // disc: "* Companies change every batch ",
  },
  {
    term: 4,
    index: 3,
    bg: "#F4D0C3",
    head: "Workshops",
    subhead: "Better Problem Solving (BPS)",
    desc: [
      "Transferrable Consulting Frameworks",
      "Excel as an Analysis Tool",
      "BPS on your Projects",
    ],
    subheadSecond: "Digital and AI Literacy",
    descSecond: ["Automating Workflows", "Building a Tech Mindset"],
    subheadThird: "Human Skills",
    descThird: [
      "Voice and Accent Training",
      "Creating Decks like a Consultant",
    ],
  },
  {
    term: 4,
    index: 4,
    bg: "#DFF4F8",
    head: "Evals",
    subhead: "Interview and Presentations",
    desc: [
      "FMCG Mock Interview",
      "Behavioural Mock Interview",
      "Roadmap on Improvements",
    ],
  },
  // {
  //   term: 5,
  //   index: 1,
  //   bg: "#FFF",
  //   head: "Practice School @ Workplace",
  //   desc: [
  //     "Gain real experience",
  //     "Get a shot to crack a PPO at same company",
  //     "If not, your practice school experience helps you crack your job",
  //   ],
  // },
];
