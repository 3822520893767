import React from "react";
import Slider from "react-slick";
import { sliderData } from "./data";

const overViewCard = () => {
  // const { ref, inView } = useInView({
  //   triggerOnce: true,
  // });
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <div className="overview-slider">
      <Slider {...sliderSettings}>
        {sliderData.map((item, index) => {
          return (
            <div key={index} className="overview-card">
              <img
                src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/e9d233fb-9c08-4517-8357-587282525862/events/banner-upload/14i86ykn1mm71xyt7y.png"
                className="quotes-img"
              />
              <div
                style={{ width: `${item.width}` }}
                className="overview-card-txt"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
              <img src={`${item.img}`} className="overview-card-img" />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default overViewCard;
