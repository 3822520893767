import React from "react";
import "./styles.scss";
import CardMob from "./CardMob";

const OnGroundProject = () => {
  return (
    <section className="basl-on-ground-project">
      <div className="index-bg projects-index">02</div>
      <p className="Projects-text"> PROJECTS</p>
      <div className="ground-project-header">
        Gain real experience with LIVE Projects
        <br />
        even before you start working
      </div>
      <div className="ground-project-header-mob">
        {/* Business Generating /<br className="pc-hide" /> Field Projects 
        <br className="mobile-hide" />
        with Industry’s Top Brands */}
        Gain experience even <br /> before you start working
      </div>
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png"
        className="ground-project-underline"
      />

      <CardMob />
    </section>
  );
};

export default OnGroundProject;
