import React from "react";
import "./styles.scss";

const TypicalDay = () => {
  const tickIcon =
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m690m85p.png";

  return (
    <div className="blp-typical-day">
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ul8hdr.png"
        loading="lazy"
        className="day-look-dots-right"
      />
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ul1dzp.png"
        loading="lazy"
        className="day-look-dots-left"
      />
      <div className="blp-typical-container">
        <h2 className="type-day-title">
          What will a typical <br className="pc-hide" /> day look like?
        </h2>
        <img
          className="underline-heading"
          src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m691a9vr.png"
        />
        <div className="typical-day-img">
          <div className="type-day-desc">
            Classes take place between Monday - Saturday.
            <br /> Here is how an average day might look like
          </div>
          <div className="type-day-desc-mob">
            <b style={{ fontWeight: "600" }}> Classes take place between</b>{" "}
            <br /> <b style={{ fontWeight: "600" }}>Monday - Saturday.</b>{" "}
            <br />
            Here's how a day could look like :
          </div>
          <hr className="mob-hide-line" />

          {/* <div className="type-day-devider"></div> */}
          <div className="type-day-schedule-basl">
            <img
              className="line-though"
              src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m690v8cl.png"
            />
            <div className="type-day-content">
              <div className="type-day-time">10:00 - 12:00 PM</div>
              <img src={tickIcon} />
              <div className="type-day-routine">Classes for the day</div>
            </div>
            <div className="type-day-content">
              <div className="type-day-time">12:15 - 1:00 PM</div>
              <img src={tickIcon} />
              <div className="type-day-routine">
                Practice of the concepts through exercises
              </div>
            </div>
            <div className="type-day-content">
              <div className="type-day-time">1:00 - 2:00 PM</div>
              <img src={tickIcon} />
              <div className="type-day-routine">Lunch</div>
            </div>
            <div className="type-day-content">
              <div className="type-day-time">2:00 - 4:00 PM</div>
              <img src={tickIcon} />
              <div className="type-day-routine">Project work in groups</div>
            </div>
            <div className="type-day-content">
              <div className="type-day-time">4:15 - 5:45 PM</div>
              <img src={tickIcon} />
              <div className="type-day-routine">Feedback on your work</div>
            </div>
            <div className="type-day-content">
              <div className="type-day-time">6:00 - 7:00 PM</div>
              <img src={tickIcon} />
              <div className="type-day-routine">
                Human Skills including Voice and Accent Training
              </div>
            </div>
            <div className="type-day-content">
              <div className="type-day-time">7:15 - 8:15 PM</div>
              <img src={tickIcon} />
              <div className="type-day-routine">
                Additional huddles with experts for clarifying any concept
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypicalDay;
