import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import "./styles.scss";
import newsImages from "./data";

const TrainedAndInTheNews = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [marqueeSpeed, setMarqueeSpeed] = useState(67);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const updateScreenSize = () => {
        const mobile = window.innerWidth <= 768;

        setIsMobile(mobile);
        setMarqueeSpeed(mobile ? 30 : 67);
      };

      updateScreenSize(); // Set initial state
      window.addEventListener("resize", updateScreenSize);

      return () => window.removeEventListener("resize", updateScreenSize);
    }
  }, []);

  return (
    <div className="blp-trained-and-news">
      <div>
        <div className="mobile-stu-card">
          <p className="student-text">10,000+ Students Trained</p>
          <Marquee
            className="marquee"
            speed={marqueeSpeed}
            pauseOnHover={false}
            loop={0}
            direction="right"
          >
            <img
              className="students"
              src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82pvsv4.webp"
              alt="iima"
            />
          </Marquee>
        </div>
        <div className="mobile-news-card">
          <p className="news-text">In the News</p>
          <Marquee
            className="marquee2"
            speed={marqueeSpeed}
            pauseOnHover={false}
            loop={0}
            direction="right"
          >
            <div className="news-cont">
              {newsImages.map((news, index) => (
                <a
                  key={index}
                  href={news.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="news-anchor-tag"
                >
                  <img className="news" src={news.src} alt={news.alt} />
                </a>
              ))}
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default TrainedAndInTheNews;
