import React from "react";
import "./styles.scss";

import { useState, useRef } from "react";
import { scholarships } from "./data";

const index = () => {
  const scrollRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Group scholarships into pairs
  const groupedScholarships = [];

  for (let i = 0; i < scholarships.length; i += 2) {
    groupedScholarships.push(scholarships.slice(i, i + 2));
  }

  // Update currentIndex on scroll
  const handleScroll = () => {
    if (scrollRef.current) {
      const scrollLeft = scrollRef.current.scrollLeft;
      const itemWidth = scrollRef.current.offsetWidth; // Width of one full view
      const index = Math.round(scrollLeft / itemWidth);

      setCurrentIndex(index);
    }
  };

  // Scroll to selected dot index
  const scrollToIndex = (index) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.offsetWidth;

      scrollRef.current.scrollTo({
        left: index * itemWidth,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="basl-scolarship">
      <div className="basl-scolarship-header">
        Scholarships of upto ₹40,000 are available under 6 categories.
      </div>
      <div className="basl-scolarship-sub">
        If you’ve got the skill, we will not let financing get in the way of
        your aspirations.
        <br />
        Check eligibility with your counselor.
      </div>
      <div className="scolar-flex">
        <div id="scholar-1" className="scolarshipCard">
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1um204a.png"
            className="scholorimg"
          />
          <div className="scholar-cont">
            <div className="schol-head">
              Women in Sales
              <br />
              Scholarship
            </div>
            <div className="scholar-det">
              For women who want to join the sales trenches and demonstrate high
              capability for it
            </div>
          </div>
        </div>
        <div id="scholar-1" className="scolarshipCard">
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1um352h.png"
            className="scholorimg"
          />
          <div className="scholar-cont">
            <div className="schol-head">
              Entrepreneurial
              <br />
              Ambition Scholarship
            </div>
            <div className="scholar-det">
              For folks who want to start-up with a strong sales muscle
            </div>
          </div>
        </div>
        <div id="scholar-1" className="scolarshipCard">
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1um4986.png"
            className="scholorimg"
          />
          <div className="scholar-cont">
            <div className="schol-head">
              Sales Excellence <br />
              Scholarship
            </div>
            <div className="scholar-det">
              For folks who have prior
              <br /> sales experience and <br />
              have excelled at it
            </div>
          </div>
        </div>
      </div>
      <div className="scolar-flex">
        <div id="scholar-1" className="scolarshipCard">
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1um5vkl.png"
            className="scholorimg"
          />
          <div className="scholar-cont">
            <div className="schol-head">
              Young India <br />
              Scholarship
            </div>
            <div className="scholar-det">
              For freshers who have
              <br /> delivered exceptional results in their extra/co-curriculars
            </div>
          </div>
        </div>
        <div id="scholar-1" className="scolarshipCard">
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1um7idy.png"
            className="scholorimg"
          />
          <div className="scholar-cont">
            <div className="schol-head">
              Need Based <br />
              Scholarship
            </div>
            <div className="scholar-det">
              For folks from families who dont have enough means to finance
              their education
            </div>
          </div>
        </div>
        <div id="scholar-1" className="scolarshipCard">
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1um9fmr.png"
            className="scholorimg"
          />
          <div className="scholar-cont">
            <div className="schol-head">
              Standout Performance
              <br />
              Scholarship
            </div>
            <div className="scholar-det">
              For folks who ace it in <br />
              Admission Process
            </div>
          </div>
        </div>
      </div>
      <div className="scolar-flex-mob">
        {/* Scrollable container */}
        <div
          className="scholar-cards-row"
          ref={scrollRef}
          onScroll={handleScroll}
        >
          {groupedScholarships.map((group, index) => (
            <div key={index} className="scholar-slide">
              {group.map((scholarship) => (
                <div key={scholarship.id} className="scolarshipCard">
                  <img src={scholarship.img} className="scholorimg" alt="" />
                  <div className="scholar-cont">
                    <div className="schol-head">{scholarship.head}</div>
                    <div className="scholar-det">{scholarship.detail}</div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>

        {/* Dots Navigation */}
        <div className="dots-container">
          {groupedScholarships.map((_, index) => (
            <span
              key={index}
              className={`dot ${currentIndex === index ? "active" : ""}`}
              onClick={() => scrollToIndex(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default index;
