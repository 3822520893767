export const faqsData = [
  {
    title:
      "Why should I choose Kraftshala’s PGP in Sales and Business Leadership over a traditional MBA?",
    category: "general",
    description: `<p>
      For better ROI, faster. Let us explain - If you are getting an admission in the top 20-30 colleges of the country, you should take it up if you can afford it. But if not, the ROI beyond that starts to look very bad. You spend 10-12L in fees and get very mediocre jobs of 5-7L CTC. Placement rates have also been plummeting across most colleges with no accountability at the institute’s end.
      <br /><br />
      Kraftshala, from Day 1, has been focused on driving placement outcomes for EVERYONE and not just doing lip service by placing only the top 5-10% of the students. Kraftshala PGP in Sales and Business Leadership has been designed in such a way that by the end of the program, you will be equipped with all the skills necessary to land and succeed in a top tier Sales and Business role. Placement Rates, which is % of students placed if what we chase and optimize for. We take accountability by promising that if your job < 7.5L, you get most of your fee refunded.
    </p>`,
  },
  {
    title: "Why is this program online and not offline?",
    category: "general",
    description: `<p>
      We wanted to keep the program high on ROI - which means both time and money. Online allows us to keep the program fees lower. While offline has its advantages of feeding off each other’s energy, over the last 3 years, Kraftshala has built a program structure which delivers equally good learning outcomes. Online also allows us to get the best experts across the world to come and interact with you irrespective of the location.
    </p>`,
  },
  {
    title: "Can I self-pace the business management course?",
    category: "general",
    description: `<p>
      No. The PGP in Sales and Business Leadership is a full-time program, conducted online. You have full day classes, projects, workshops and small group coaching on functional and behavioral aspects. So, self-pacing this program is not an option.
    </p>`,
  },
  {
    title:
      "What is the eligibility criteria for Kraftshala’s PG Program in Sales and Business Leadership?",
    category: "eligibility",
    description: `<p>
      To be eligible for PG Program in Sales and Business Leadership, you must-have:<br/>
      </p>
      <ul>
        <li>Graduation: You should have completed your graduation. If you’re in the final year, you can still pursue the program in case you don't have a full-time college to go to. If you don't have a graduation, you can discuss your application with the admissions team to check if the program would be suitable for you.</li>
        <li>Work Experience: While 1-4 years of work experience is ideal, we are open to considering freshers and candidates with exceptional profiles.</li>
      </ul>
      <p>Admission process looks for:</p>
      <ol>
        <li>Ability to communicate impactfully with a logical flow and brevity</li>
        <li>High ownership demonstrated through your past experiences</li>
        <li>Learning agility which keeps you curious, but also reflective and humble</li>
      </ol>
    `,
  },
  {
    title:
      "I’ve just completed/will be completing my undergraduate studies. Is Kraftshala’s PGP in Sales and Business Leadership a right fit for me?",
    category: "eligibility",
    description: `<p>
      Work experience is not a mandatory requirement for admission. If you feel you have demonstrable leadership potential, we encourage you to apply to PGP in Sales and Business Leadership! As long as you put in the work and build your skills, results will follow - e.g. A fresher from our pilot cohort has achieved CTCs of 17.2LPA post completion of their training.
    </p>`,
  },
  {
    title: "How many years of work experience do I need to be admitted?",
    category: "eligibility",
    description: `<p>
      While 1-4 years of work experience is ideal, it is not a mandatory requirement for admission. Applicants in their final year of graduation with demonstrable experiences showing high ownership and learning agility are encouraged to apply.
    </p>`,
  },
  {
    title: "What's the minimum qualifying marks in past academic results?",
    category: "eligibility",
    description: `<p>
      Kraftshala does not have a minimum marks requirement. We focus on your learning, experiences, and performance in the admission process.
    </p>`,
  },
  {
    title: "Is there an age limit for entry?",
    category: "eligibility",
    description: `<p>
      There is no age limit for entry. However, the programme is most suitable for those who are at an early stage in their careers. If you have more work experience, we encourage you to get in touch with the admissions team at contact@kraftshala.com, who will evaluate your profile and advise you accordingly.
    </p>`,
  },
  {
    title:
      "I don't have an undergraduate degree. Can I still be considered for admission?",
    category: "eligibility",
    description: `<p>
      Yes! While the majority of our participants have undergraduate degrees, this is not compulsory for admission. Candidates who do not hold an undergraduate degree, but who have an excellent career record, examination scores, leadership potential, and solid reasons for dropping out / applying without graduation are welcome to apply.
    </p>`,
  },
  {
    title: "What's the selection process like?",
    category: "admission",
    description: `<p><b>Step 1: You complete the online application -</b>

   <ul><li> Upload your latest resume containing academic details, current experience etc.</li>
    <li>We would also love to know your professional goals and how Kraftshala’s <br/> PG program in Sales and Business Leadership fits in your career aspirations. </li></ul> </p>
    <p style="margin-top: -10px;"><b>Step 2: Appear for the interview</b><br/>
   Post your discussion with our admissions counselor on your application, you will be moved to a 1-1 interview with our panel. This will be a 15 - 20 minutes chat about your profile, your past experiences and situation based questions to evaluate your thought process. You can have  upto 2 such rounds depending on your performance and our conviction in your candidature.
    <br/>
    Scholarship decisions will also be made based on your performance in this round.<br/><br/>
    <b>Step 3: Final Admission Decision</b><br/>
    Final decisions are declared within 7 days post the completion of your interview.<br/>
    
    <b> If Accepted: </b>
    <ol><li>You receive your offer letters for the PGP Sales and Business Leadership.
    </li><li>Scholarship Decisions will also be announced </li><li>Offered students have a period of 7 days to confirm their admission with the payment of the fee.</li></ol><br/>
    <b>If Rejected:</b>
    <p style="margin-top: 0px;">If you’re not accepted, we will share reasons for it. You are free to reapply to the PGP Sales and Business Leadership in the subsequent admission round after having worked on the feedback.
      </p>
    `,
  },
  {
    title:
      "How do i apply for scholarships for admission in the PG program for Sales and Business Leadership?",
    category: "admission",
    description: `<p>Kraftshala offers Scholarships under 6 categories for the PGP in Sales and Business Leadership program. Scholarships are decided based on your past background and your performance in the admission process. You can check your eligibility with your counselor. If you’ve got the skill, we will not let financing get in the way of your aspirations. 
    </p>
    <p>The following scholarships are available:</p>
    <p><b>Women in Sales Scholarship </b>- For  women  who want to join the sales trenches and demonstrate high capability for it

    </p><p><b>Entrepreneurial Ambition Scholarship</b> - For folks who want to start-up with a strong sales muscle

    </p><p><b>Sales Excellence Scholarship</b> - For candidates with relevant experience in Sales 

    </p><p><b>Young India Scholarship</b> - For freshers who have delivered exceptional results in their extra/co-curriculars

    </p><p><b>Need Based Scholarship</b> - For folks from families who dont have enough means to finance their education

    </p><p><b>Standout Performance Scholarship</b> - For candidates who’ve done exceptionally well in the admission process
    </p>
    <p>Please note that students enrolling through Scholarships are not eligible for refunds as they are anyways coming in at a lower fees.</p>
    `,
  },
  {
    title: "Are all candidates interviewed?",
    category: "admission",
    description: `Post the application and conversation with our Admissions team, candidates are shortlisted for interviews. Only shortlisted candidates are invited for an interview.`,
  },
  {
    title:
      "If my application has been unsuccessful, may I reapply in the next round?",
    category: "admission",
    description: `Unsuccessful applicants can reapply for the next round. You should be asking for feedback though, work on it and only then reapply.`,
  },
  {
    title: "What are the fees and financing options?",
    category: "admission",
    description: `The fees for the program is ₹175,000-200,00 depending on which round you apply and enrol in. The program fee can be paid through all common online modes of payment like credit card, UPI etc. You also have the option of paying through low cost EMIs starting from ~10,000/month.`,
  },
  {
    title: "Can I get a refund if I don't want to proceed?",
    category: "admission",
    description: `No, fees once paid cannot be refunded. <br/>
        The only eligible clause of a refund is when you don't get placed after being active and participating in all training and placement processes and still being unable to get placed at the minimum CTC promised. 
        `,
  },
  {
    title:
      "Will I get placements post completion of the PG program in Sales and Business Leadership?",
    category: "outcome",
    description: `<p>All of Kraftshala’s programs are focused on placements as an outcome. <br/>
        You will have to put in the work in the program and once you do, you can definitely expect great outcomes. Average CTC of our pilot batch was 10LPA and highest CTC went upto 17.2LPA.
        </p><p>
        We strongly believe that the job of institutions is not just to help candidates get a degree or a certificate but to actually improve employability for you which should culminate in an excellent job post completion. We thereby take ownership of the entire process from admission to training to placements in-house and dont leave you to fend for yourself.
        </p><p>
        Like all Kraftshala’s programs, this also comes with a placement accountability pledge, which means that if you don't get a job with a minimum CTC, we refund 60% of your fee back. Which means we don't get paid the entire amount. Hence we will dedicate every last resource to helping you get the opportunities you deserve.
        </p><p>
       Does that mean that it's a free pass? No- you will have to put in the work in the program, truly apply yourself across the multiple tests and interviews. But once you do, the outcomes will come in. </p>`,
  },
  {
    title:
      "After completing the PGP in Sales and Business Leadership, what kind of job roles can I expect to find?",
    category: "outcome",
    description: `<p>We concentrate on high tier roles in SaaS, B2B and some high potential FMCG Sales roles. These roles come with a high CTC as well as excellent opportunities for growth and leadership projects within companies. </p>`,
  },
  {
    title:
      "Do I directly get a job of ₹7.5L or do I get to do an internship first?",
    category: "outcome",
    description: `<p>We get both kinds of roles. As you move to the placement pool, you start applying for companies. These roles could be internship / traineeship or in a select few cases, a direct offer of a full-time job. Whether the candidate gets an internship or a full-time offer depends on the company’s requirements, internal process and the candidate's performance in the interview.
        </p><p>
        If you get into a Traineeship, most of you will convert it into full-time roles. But, if you’re not able to convert, we bring you back in the placement process.
        <p>Either way, if you don’t get a job with a minimum CTC of ₹7.5LPA, we refund 60% of your fee</p> </p>`,
  },
  {
    title: "When do the placements start?",
    category: "outcome",
    description: `<p>
      The placement process begins upon the completion of your program which is dependent on your clearing the tests and interviews as outlined by the Assessment Center.
       </p>`,
  },
  {
    title: "How long does it take to get placed?",
    category: "outcome",
    description: `<p>
    Historically, we have seen the following trend for our programs: <br/>
    Total 60% of our students get placed by Week 6 of the placement drive<br/>
    Total 80% of students get placed by Week 8 of the placement drive<br/>
    Remaining 20% get placed over the next 4-6 weeks as these are students who typically need more help. 
    </p>`,
  },
  {
    title: "Am I supposed to apply for all jobs that come in?",
    category: "outcome",
    description: `<p>
    No. You get a certain amount of passes which you can utilize to let an opportunity pass. All the information about the company is made available before you apply. Once applied, you should not back out as it impacts the chances of everyone else as well. 
    </p>`,
  },
  {
    title:
      "What if I don't want to move cities? Can I get a job in one specific city itself? ",
    category: "outcome",
    description: `<p>
  Sales roles require people who are flexible and willing to travel. The HR team of our recruiting partners would ensure that you are well settled and have all the support you need in case you have to move to a new city for work. 
  </p><p>
  Additionally, you can use your limited passes to pass an opportunity that may be in a city you don’t wish to move to. It’s important to note that these passes are finite, though. 
  </p>`,
  },
  {
    title: "Do I get a degree or diploma after completing the program?",
    category: "outcome",
    description: `<p>
  No, we dont provide degrees or diplomas. We focus on giving you specialized skills, networking opportunities and focus on getting you hired at a job that will pay well and put you on an accelerated path of top leadership roles.
   </p>`,
  },
];
