import React from "react";
import "./styles.scss";

const PlacementProcess = () => {
  return (
    <>
      <div className="career-title mtop-3">Placement process</div>
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png"
        loading="lazy"
        className="placement-underline"
      />
      <img
        className="dots-placement"
        src={
          "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/e9d233fb-9c08-4517-8357-587282525862/events/banner-upload/14i86yoa2pm838uu98.webp"
        }
      />
      <div className="placement-process-wrappper">
        <div className="first-place-div">
          Appear in the <br /> Placement <br /> Process
        </div>
        <div className="second-place-div">
          Crack a <span>Full-Time</span> Eligible Offer <br /> directly
        </div>
        <div className="third-place-div">
          <span>
            Conversion: <br />{" "}
          </span>{" "}
          An eligible offer
        </div>
        <div className="fourth-place-div">
          Crack a <br /> <span>Traineeship</span> Offer
        </div>
        <div className="fifth-place-div">
          <span>Non-conversion:</span> You're back into the placement pool and
          your traineeship experience helps you crack a job
        </div>
      </div>
      {/* <img
        className="dots-placement-mob"
        src={
          "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ykmm6m74ytgtt.png"
        }
      /> */}
      {/* for mobile */}
      <div className="placement-process-wrappper-mobile">
        <img
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/e9d233fb-9c08-4517-8357-587282525862/events/banner-upload/14i86yo8tqm836ryt3.webp"
          loading="lazy"
          className="placement-prep-img-mob pc-hide"
        />

        {/* <div className="first-sub-div-mob">
          <div className="first-place-div-mob">
            {" "}
            Appear in the <br /> Placement <br /> Process
          </div>
        </div>
        <div className="second-sub-div-mob">
          <div className="second-place-div-mob">
            Crack a <span>Full-Time</span> Eligible Offer <br /> directly
          </div>
          <div className="third-place-div-mob">
            Crack a <br /> <span>Traineeship</span> Offer
          </div>
        </div>
        <div className="third-sub-div-mob">
          <div className="fourth-place-div-mob">
            <span>
              Conversion: <br />{" "}
            </span>{" "}
            An eligible offer
          </div>
          <div className="fifth-place-div-mob">
            <span>Non-conversion:</span> You're back into the placement pool and
            your traineeship experience helps you crack a job
          </div>
        </div> */}
      </div>
    </>
  );
};

export default PlacementProcess;
