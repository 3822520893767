import React, { useEffect, useState } from "react";
import Seo from "../../components/Seo/seo";
import Layout from "../../components/Layout/layout";
import NavBar from "../../components/NavBar/navbar";
import Overview from "../../components/BuisnessSalesLeadership/Overview/overview";
import Benefits from "../../components/BuisnessSalesLeadership/Benefits/benefits";
import ScolarshipSub from "../../components/BuisnessSalesLeadership/Scholarship/ScolarshipSub";
import Learning from "../../components/BuisnessSalesLeadership/Learning";
import Faq from "../../components/BuisnessSalesLeadership/FAQS/faq";
import PlacementsMain from "../../components/BuisnessSalesLeadership/Placements";
import Roles from "../../components/BuisnessSalesLeadership/Roles/Roles";
import Tools from "../../components/BuisnessSalesLeadership/Tools/Tools";
import Outcomes from "../../components/BuisnessSalesLeadership/Outcomes/Outcomes";
import AlumniCarousal from "../../components/BuisnessSalesLeadership/Alumni";
import CohotMasanory from "../../components/BuisnessSalesLeadership/CohotMasonary";
import KsJourney from "../../components/BuisnessSalesLeadership/KsJourney";
// import WhatsappWidget from "../../components/WhatsappWidget";
import Trainers from "../../components/BuisnessSalesLeadership/Trainers/trainers";
// import Fee from "../../components/MLPMainComp/Fee/fee";
import "./styles.scss";
import CareerPrep from "../../components/BuisnessSalesLeadership/CareerPrep";
import AboutProgram from "../../components/BuisnessSalesLeadership/AboutProgram";
import Sales from "../../components/BuisnessSalesLeadership/Sales";
import Fee from "../../components/BuisnessSalesLeadership/Fee";
import Scholarship from "../../components/BuisnessSalesLeadership/Scholarship";
import BASLForm from "../../components/Forms/BASLForm";
import { useLocation } from "@reach/router";
import IvrSection from "../../components/BuisnessSalesLeadership/Ivr/index";
import OnGroundProject from "../../components/BuisnessSalesLeadership/OnGroundProject";
import HeroSection from "../../components/BuisnessSalesLeadership/HeroSection/heroSection";
import TypicalDay from "../../components/BuisnessSalesLeadership/TypicalDay/TypicalDay";
import TrainedAndInTheNews from "../../components/BuisnessSalesLeadership/TrainedAndInTheNews/TrainedAndInTheNews";
import MainHero from "../../components/BuisnessSalesLeadership/MainHeroSection/MainHero";
import AdmissionAndCriteria from "../../components/BuisnessSalesLeadership/AdmissionAndCriteria/AdmissionAndCriteria";

const BusinessSalesTrainingProgram = (props) => {
  const location = useLocation();
  const navBarContent = [
    {
      name: "Benefits",
      id: "benefits",
    },
    {
      name: "Learning",
      id: "learning",
    },

    {
      name: "Projects ",
      id: "projects",
    },
    {
      name: "Community ",
      id: "community",
    },
    {
      name: "Outcomes",
      id: "outcomes",
    },
    // {
    //   name: "Experts",
    //   id: "experts",
    // },
    {
      name: "Curriculum",
      id: "curriculum",
    },
    {
      name: "Admission",
      id: "admission",
    },

    {
      name: "FAQs",
      id: "sales-faq",
    },

    {
      name: "Apply Now",
      id: "hero-div",
      //formLink: true,
    },
  ];

  const [isHidden, setIsHidden] = useState(true);
  const [front, setFront] = useState(true);

  useEffect(() => {
    function handleScroll() {
      const currentScrollPosition = window.pageYOffset;

      setIsHidden(currentScrollPosition < 800); // Change 100 to the number of pixels after which you want to hide the element
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <Seo
        title="India First Online Sales Course With Job Placement Upto 17 LPA"
        description="Join India's best online sales course with placements of ₹7.5 - 17 LPA. 5 months full-time sales program to launch your sales leadership career. Enroll Now!"
        url="https://www.kraftshala.com/sales-course/"
      />

      <div id="HeroSection" className="main-mlp-page basl-page">
        {/* <Marquee
          gradient={false}
          speed={80}
          pauseOnHover={true}
          className="marquee-container-header"
          loop={0}
        >
          <i className="marquee-item">
            * Applications open for 2025 Batch now *
          </i>
        </Marquee> */}

        <Layout formLink="/marketing-launchpad/digital-marketing-course/">
          <div className="basl-new-main-hero">
            <MainHero />
          </div>
          <div className="hiring-partners-area-mob-sbl pc-none">
            <div
              className="hiring-partners-wrapper"
              onMouseEnter={() => setFront(false)}
              onMouseLeave={() => setFront(true)}
              onTouchStart={() => setFront(false)}
              // onTouchEnd={() => setFront(true)}
            >
              {front ? (
                <div className="hiring-partners-mob front">
                  <p className="hiring-text">
                    Hiring <br />
                    partners
                  </p>
                  <div className="hiring-part-line"></div>
                  <div className="img-container">
                    <img
                      style={{ height: "31px", width: "30px" }}
                      className="hirin-part-sbl-img"
                      src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ypfqjm8h8j5ep.webp"
                      alt="hiring-partner-img"
                      rel="preload"
                    />
                    <img
                      style={{ height: "27px", width: "55px" }}
                      className="hirin-part-sbl-img"
                      src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ypfqjm8h8mt45.webp"
                      alt="hiring-partner-img"
                      rel="preload"
                    />
                    <img
                      style={{ height: "34px", width: "63px" }}
                      className="hirin-part-sbl-img"
                      src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ypfqjm8h8o7nd.webp"
                      alt="hiring-partner-img"
                      rel="preload"
                    />
                    <img
                      style={{ height: "11.28px", width: "57.66px" }}
                      className="hirin-part-sbl-img"
                      src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ypfqjm8h8yz38.png"
                      alt="hiring-partner-img"
                      rel="preload"
                    />
                  </div>
                </div>
              ) : (
                <div className="hiring-partners-mob back">
                  <p className="hiring-text">
                    Hiring <br />
                    partners
                  </p>
                  <div className="hiring-part-line"></div>
                  <div className="img-container">
                    <img
                      style={{ height: "22px", width: "49px" }}
                      className="hirin-part-sbl-img"
                      src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ypfqjm8h91tnv.png"
                      alt="hiring-partner-img"
                      rel="preload"
                    />
                    <img
                      style={{
                        height: "11px",
                        width: "60px",
                        marginTop: "3px",
                      }}
                      className="hirin-part-sbl-img"
                      src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ypfqjm8h935p0.png"
                      alt="hiring-partner-img"
                      rel="preload"
                    />
                    <img
                      style={{
                        height: "11px",
                        width: "53.67px",
                        marginTop: "3px",
                      }}
                      className="hirin-part-sbl-img"
                      src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ypfqjm8h949pg.png"
                      alt="hiring-partner-img"
                      rel="preload"
                    />
                    <img
                      style={{
                        height: "30px",
                        width: "52px",
                        marginBottom: "8px",
                      }}
                      className="hirin-part-sbl-img"
                      src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ypfqjm8h95gey.webp"
                      alt="hiring-partner-img"
                      rel="preload"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="hero-div basl-hero-div" id="hero-div">
            <div className="basl-hero-bg"></div>
            {!location?.state?.formFields &&
              !location?.state?.formFields?.form_submitted && (
              <HeroSection
                urlParams={location?.search}
                formfields={location?.state?.formFields}
              />
            )}
            <BASLForm
              search={location?.search}
              pathname={location?.pathname}
              locState={location?.state}
            >
              {/* <ThankYou
                search={location?.search}
                formfields={location?.state?.formFields}
                campaignId={location?.state?.campaignId}
              /> */}
            </BASLForm>
            {/* <HeroSectionMb /> */}
          </div>
          <TrainedAndInTheNews />
          {/* <GoTo /> */}
          {/* <div className="component-center">
            <Floater />
          </div> */}
          <div className="basl-main-overview bg-mob-size">
            <NavBar
              content={navBarContent}
              offset={902}
              applyLink={`/marketing-launchpad/digital-marketing-course/${location?.search}`}
            />
            <Sales />
            <AboutProgram />
            <div id="benefits" className="section">
              <Overview />
            </div>
            {/* <div id="benefits" className="section"> */}
            {/* </div> */}
            <div className={isHidden ? "fixed-bottomHidden" : "fixed-bottom"}>
              {!isHidden && (
                <>
                  {" "}
                  <button
                    className={"footer-btn"}
                    onClick={() => {
                      document.querySelector("#hero-div").scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                      });
                    }}
                  >
                    Apply now
                  </button>
                </>
              )}
            </div>
            <div className="section">
              <PlacementsMain />
              <Roles />
            </div>
            <div className="section" id="learning">
              <Learning />
            </div>
            <div id="projects" className="section">
              <OnGroundProject />
            </div>
            <Tools />
            <div id="community" className="section">
              <Trainers />
            </div>
            <div id="outcomes" className="section">
              <Outcomes />
            </div>
            {/* <div id="admission-process" className="section">
              <AdmissionProcess urlParams={location?.search} />
            </div> */}
            <div id="curriculum" className="section">
              <KsJourney />
            </div>
            <div className="section" id="typicalDay">
              <TypicalDay />
            </div>
            <div className="section">
              <CareerPrep />
            </div>
            <Benefits />
            <AdmissionAndCriteria />
            <div id="fees" className="section">
              <Fee />
            </div>
            <div id="fee" className="section">
              <Scholarship />
            </div>
            <CohotMasanory urlParams={location?.search} />
            <ScolarshipSub />
            {/* <div id="fee" className="section"> */}
            {/* <SalesForm /> */}
            {/* </div> */}

            <div id="basl-alumni" className="section">
              <AlumniCarousal />
            </div>
            {/* <NewsCarousal /> */}
            <div id="sales-faq" className="section">
              <div className="faq">
                <Faq />
              </div>
            </div>
            <div id="Ivr" className="section">
              <IvrSection />
            </div>
          </div>
        </Layout>
      </div>
      {/* <WhatsappWidget link="https://wa.me/message/HHNAOZDIMB4ZE1" /> */}
    </React.Fragment>
  );
};

export default BusinessSalesTrainingProgram;
