import React from "react";
import "./styles.scss";

const AboutProgram = () => {
  return (
    <>
      <div id="about-bsl-program">
        <div className="text-content">
          <h2 className="about-program-header desk">
             Sales: Where Business Leadership Begins
            <br />A role that prepares you for the top from Day One.
          </h2>
          <h2 className="about-program-header mob">
             
            <p className="mob-sales-line">
              Sales: Where Business Leadership Begins{" "}
            </p>
            <br />A role that prepares you for the top from Day One.
          </h2>
        </div>
        <img
          className="about-program-img"
          src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82s8sqq.webp"
          width={"100%"}
          loading="lazy"
        />
        <img
          className="about-program-img-mb"
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/e9d233fb-9c08-4517-8357-587282525862/events/banner-upload/14i86yo8tqm835sry2.webp"
          width={"100%"}
          loading="lazy"
        />
      </div>
    </>
  );
};

export default AboutProgram;
