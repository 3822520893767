export const data = [
  {
    project: "Project 1:",
    title: "Business Challenge",
    duration: "35-40 Hours",
    goalText: "Immersion into the business world",
    goalTextMob: "Immersion into the business world",
    ScopeText: `You solve a business problem by doing research,</br> identifying gaps & applying first principles for solutions.`,
    ScopeTextMob:
      " You solve a business problem by doing research, identifying gaps & applying first principles for solutions",
    disclaimer: "",
    color: "#243E60",
    hColor: "#243E60",
    scopeIcon:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m650wofx.png",
    scope:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64uqyy5.png",
    goalIcon:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64uhwzz.png",
    durationIcon:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64t94ip.png",
    bg:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9b689.png",
    icon:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64v8zar.png",
    mobImg:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9cyed.png",
    highlight: "Final Showcase",

    points: [
      "Problem Understanding",
      "Category, Industry and Business deep-dive",
      "Customer profiles / needs mapping",
      "Process Understanding",
      "Gap Identification",
      "First-Principle Solutioning",
      "Feedback & Iteration",
    ],
    pointsMob: [
      "Problem Understanding",
      "Category, Industry and Business deep-dive",
      "Customer profiles / needs mapping",
      "Process Understanding",
      "Gap Identification",
      "First-Principle Solutioning",
      "Feedback & Iteration",
    ],
  },
  {
    project: "Project 2:",
    title: "Core Sales and Persuasion",
    duration: "75-80 Hours",
    goalText: "Doing value additive conversations",
    goalTextMob: "Doing value additive conversations",
    ScopeText: `You work with a B2B / FMCG firm & call 250+ leads </br> for conversions! You also identify & share a plan solving </br> for bottlenecks in the sales funnel`,
    ScopeTextMob:
      "You work with a B2B / FMCG firm & call 250+ leads for conversions! You also identify & share a plan solving for bottlenecks in the sales funnel",
    disclaimer: "* Companies change every batch",
    color: "#D86136",
    hColor: "#D86136",
    scopeIcon:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m650ut07.png",
    scope:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64ursam.png",
    goalIcon:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64uiscy.png",
    durationIcon:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64t9w7x.png",
    bg:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9qy1t.png",
    icon:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64vbqpy.png",
    mobImg:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9sfkx.png",
    highlight: "Final Showcase",
    points: [
      "Company orientation",
      "Mock calls + Feedback",
      "Actual Cold Calls",
      "Solving problems, handling objections &driving conversions",
      "Weekly mentorship to improve your calls",
      "Completing trackers, building artifacts",
      "Report Making, feedback + iterations",
    ],
    pointsMob: [
      "Company orientation",
      "Mock calls + Feedback",
      "Actual Cold Calls",
      "Solving problems, handling objections & moving leads forward",
      "Weekly mentorship ",
      "Completing trackers & artifacts",
      "Report Making,feedback + iterations",
    ],
  },
  {
    project: "Project 3:",
    title: "B2B Sales",
    duration: "120 hours",
    goalText: "Learn B2B SaaS sales via driving REAL IMPACT",
    goalTextMob: " Learn B2B SaaS sales ",
    ScopeText: `You do outbound for a B2B SaaS firm. Find </br> customers, make calls, follow-up & set up meetings. You </br> also identify & solve for bottlenecks in the sales funnel`,
    ScopeTextMob:
      "You do outbound for a B2B SaaS firm. Find customers, make calls, follow-up & set up meetings. You also identify & solve for bottlenecks in the sales funnel ",
    color: "#F1AE13",
    scopeIcon:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m650y2cj.png",
    scope:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64ussxq.png",
    hColor: "#F1AE13",
    goalIcon:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64ujqb7.png",
    durationIcon:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64taw3s.png",
    bg:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9vb1y.png",
    icon:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64ve8ay.png",
    mobImg:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9y0nx.png",
    highlight: "Final Showcase",
    points: [
      "Company Orientation",
      "Clarity on category, ICPs, DMUs / User Journeys etc",
      "Mock Calls + Feedback",
      "Becoming the SDR / BDR - Lead Gen and setting up sales qualified meetings",
      "Tracking and analysis of key sales metrics to improve outcomes",
      "Weekly Mentorship on how to meet outcomes",
      "Completing trackers, building artifacts",
      "Report Making, feedback + iterations",
    ],
    pointsMob: [
      "Company Orientation",
      "Clarity on category, ICPs, DMUs / User Journeys etc",
      "Mock Calls + Feedback",
      "Becoming the SDR / BDR - Lead Gen and setting up meetings",
      "Tracking and analysis",
      "Weekly Mentorship",
      "Completing trackers & artifacts",
      "Report Making, feedback + iterations",
    ],
  },
  {
    project: "Project 4:",
    title: "FMCG Sales",
    goalIcon:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64uldw3.png",
    duration: "80-100hours",
    goalText: "Learn FMCG sales via driving REAL IMPACT",
    goalTextMob: "Learn FMCG sales ",
    ScopeText: `You work with a FMCG firm for driving value - you </br> get mapped to a market for either driving distribution / </br> improving efficiencies / enabling sales folks / or </br> mapping growth opportunities.`,
    ScopeTextMob:
      "You work with a FMCG firm for driving value. You work on driving distribution / improving efficiencies / enabling sales folks & more!",
    color: "#0EDCA1",
    hColor: "#0EDCA1",
    scopeIcon:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m650z8yp.png",
    scope:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64ussxq.png",
    durationIcon:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64tbpeg.png",
    bg:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9vb1y.png",
    icon:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64vhfj7.png",
    mobImg:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9y0nx.png",
    highlight: "Final Showcase",
    points: [
      "Company / Region Orientation",
      "Meeting the customers",
      "Understanding Project and Metrics of success",
      "Building & Revising plans",
      "Building Proof of concept",
      "Weekly Mentorship on Improving Plans",
      "Getting Buy-ins from stakeholders",
      "Report Making, feedback + iterations",
    ],
    pointsMob: [
      "Company / Region Orientation",
      "Meeting the customers",
      "Clarity on Project & Success Metrics",
      "Building & Revising plans",
      "Building Proof of concept",
      "Weekly Mentorship",
      "Getting Buy-ins from stakeholders",
      "Report Making, feedback + iterations",
    ],
  },
];
