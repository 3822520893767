import React from "react";
import LazyLoad from "react-lazyload";
import { CommunityImages } from "./data";
import { CommunityImagesSecond } from "./data";

import "./styles.scss";

function Trainers() {
  return (
    <div className="basl-main-trainers section">
      <div className="will-be-training will-be-training-bsl mlp-bg">
        <div class="index-bg">03</div>
        <div className="trainers-container-basl">
          <div className="basl-trainer-sub">COMMUNITY</div>
          <div className="basl-trainer-sub-head pc-hide">
             Learn and Network with
            <br /> the BEST in the country
          </div>
          <div className="basl-trainer-sub-head mobile-hide">
             Learn and Network with the BEST in the country
          </div>
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png"
            className="underline-trainers-basl"
          />
          <div className="trainers-desc mobile-hide">
            Not just a network, get an inner circle of amazing leaders and
            professionals in Sales and Marketing
          </div>
          <div className="trainers-marquee-container">
            {/* First marquee - Right to Left */}
            <div className="marquee marquee-reverse">
              <div className="marquee-content">
                {[...CommunityImages, ...CommunityImages].map((info, key) => (
                  <div className="trainer-card" key={key}>
                    <LazyLoad once>
                      <img
                        src={info.image}
                        alt={info.alt}
                        title={info.alt}
                        loading="lazy"
                        className="trainers-bslp-img"
                      />
                    </LazyLoad>
                  </div>
                ))}
              </div>
            </div>

            {/* Second marquee - Left to Right */}
            <div className="marquee">
              <div className="marquee-content">
                {[...CommunityImagesSecond, ...CommunityImagesSecond].map(
                  (info, key) => (
                    <div className="trainer-card" key={key}>
                      <LazyLoad once>
                        <img
                          src={info.image}
                          alt={info.alt}
                          title={info.alt}
                          loading="lazy"
                          className="trainers-bslp-img"
                        />
                      </LazyLoad>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trainers;
