import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { useInView } from "react-intersection-observer";

const Tools = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const [muted, setMuted] = useState(true);
  const [playingVideoIndex, setPlayingVideoIndex] = useState(null);
  const videoRefs = useRef([]);
  const [currentSlide, setCurrentSlide] = useState(-1);
  const [finalSlide, setfinalSlide] = useState(false);
  const handleMouseEnter = (index) => {
    try {
      // Stop any currently playing video
      videoRefs.current.forEach((video, i) => {
        if (video && i !== index) {
          video.pause();
          video.currentTime = 0;
          video.muted = true;
        }
      });

      setPlayingVideoIndex(index);
      const video = videoRefs.current[index];

      if (video) {
        video.currentTime = 0;
        video.muted = true; // Ensure it's muted on hover
        video.play().catch((error) => console.log("Video play error:", error));
      }
    } catch (error) {
      console.error("Error in handleMouseEnter:", error);
    }
  };
  const handleMouseLeave = (index) => {
    try {
      const video = videoRefs.current[index];

      if (video) {
        video.pause();
        video.currentTime = 0;
      }

      setPlayingVideoIndex(null);
      setMuted(true);
    } catch (error) {
      console.error("Error in handleMouseLeave:", error);
    }
  };

  const handleClick = (index) => {
    setPlayingVideoIndex(index);
    videoRefs.current.forEach((video, i) => {
      if (video) {
        video.muted = i !== index; // Only unmute the clicked video

        if (i === index) {
          video.play();
        } else {
          video.pause();
          video.currentTime = 0;
        }
      }
    });
  };

  // Highlighted: Added touch event handlers
  const handleTouchStart = (index) => {
    setPlayingVideoIndex(index);
    const video = document.querySelectorAll(".hover-video")[index];

    if (video) {
      video.currentTime = 0; // Reset video time
      video.play(); // Play the video
    }
  };

  const handleTouchEnd = (index) => {
    const video = document.querySelectorAll(".hover-video")[index];

    video.pause();
    video.currentTime = 0;
    setPlayingVideoIndex(null);
    setMuted(true);
  };

  useEffect(() => {
    const videos = document.querySelectorAll(".hover-video");

    videos.forEach((video) => {
      video.muted = muted;
    });
  }, [muted]);

  const handleNext = () => {
    const maxSlides = thumbnails.length - (window.innerWidth <= 800 ? 1 : 5);
    const newSlide = currentSlide + 1;

    if (newSlide > maxSlides) {
      // Smoothly transition to the first slide
      setCurrentSlide(maxSlides);
      setTimeout(() => {
        const cardContainer = document.querySelector(".trench-container");

        cardContainer.style.scrollBehavior = "auto";
        setCurrentSlide(-1);
        setTimeout(() => {
          cardContainer.style.scrollBehavior = "smooth";
        }, 50);
      }, 300);
    } else {
      setCurrentSlide(newSlide);
    }

    setfinalSlide(newSlide >= maxSlides);
  };

  const handlePrev = () => {
    const maxSlides = thumbnails.length - (window.innerWidth <= 800 ? 1 : 5);
    const newSlide = currentSlide - 1;

    if (newSlide < -1) {
      // Smoothly transition to the last slide
      setCurrentSlide(-1);
      setTimeout(() => {
        const cardContainer = document.querySelector(".trench-container");

        cardContainer.style.scrollBehavior = "auto";
        setCurrentSlide(maxSlides);
        setTimeout(() => {
          cardContainer.style.scrollBehavior = "smooth";
        }, 50);
      }, 300);
    } else {
      setCurrentSlide(newSlide);
    }

    setfinalSlide(newSlide >= maxSlides);
  };

  useEffect(() => {
    const cardContainer = document.querySelector(".trench-container");
    const slideWidth = window.innerWidth <= 800 ? 280 : 300 * 3 + 2 * 3;

    // Ensure smooth scrolling behavior by default
    cardContainer.style.scrollBehavior = "smooth";
    cardContainer.scrollLeft = currentSlide * slideWidth;
  }, [currentSlide]);

  const thumbnails = [
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82wfx98.webp",
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82wih9m.webp",
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82wlf6p.webp",
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82wprwl.webp",
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82ww1fs.webp",
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82x0lj9.webp",
  ];
  const videoSources = [
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/Number+1+-+fmcg.mp4",
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/Number+2+-+Sales+Sprint+Challenge.mp4",
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/Number+3+-+Zerodor.mp4",
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m666bhv1.mp4",
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m666e96a.mp4",
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m669ygje.mp4",
  ];

  return (
    <div className="marketing-tools">
      <div className="basl-tools-title mobile-none">IN THE TRENCHES...</div>
      <div className="basl-tools-title pc-none">IN THE TRENCHES...</div>
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png"
        className="basl-tools-undeline"
      />
      <div className="trench-container">
        <div className="trenches" ref={ref}>
          {thumbnails.map((thumbnail, index) => (
            <div className="trench" key={index}>
              <div className="reel-container">
                <video
                  ref={(el) => (videoRefs.current[index] = el)}
                  className={`hover-video ${
                    playingVideoIndex === index ? "show" : "hide"
                  }`}
                  width="350"
                  height="500"
                  onMouseLeave={(e) => handleMouseLeave(index, e.target)}
                  // onClick={handleClick}
                  onClick={() => handleClick(index)}
                  onTouchStart={() => handleTouchStart(index)}
                  onTouchEnd={() => handleTouchEnd(index)}
                  muted={muted}
                  playsInline
                >
                  <source src={videoSources[index]} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <img
                  src={thumbnail}
                  alt={`thumbnail-${index}`}
                  className={`thumbnail ${
                    playingVideoIndex === index ? "hide" : "show"
                  }`}
                  onMouseEnter={() => handleMouseEnter(index)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="basl-btn-container">
        <button
          className="basl-prevBtn"
          style={{
            backgroundImage:
              "url('https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6ao5kb5.png')",
          }}
          onClick={handlePrev}
        ></button>
        <button
          className="basl-nxtBtn"
          style={{
            backgroundImage:
              "url('https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6ao77j1.png')",
          }}
          onClick={handleNext}
        ></button>
      </div>
      <a
        href="https://www.instagram.com/kraftshala/"
        target="_blank"
        className="see-on-insta"
      >
        See more on Instagram{" "}
        <img
          className="insta"
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1uco8ms.png"
        />
      </a>
    </div>
  );
};

export default Tools;
