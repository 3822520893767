export const eligibilty = [
  {
    head: "Education",
    desc: "We encourage students from all educational fields to apply",
    img:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yjwj7m6yzbee4.png",
  },
  {
    head: "Professional Experience",
    desc: "0-4 years of experience is ideal but not a necessity",
    img:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yjwj7m6yz70zo.png",
  },
  {
    head: "Intent",
    desc: "Deep interest & intent in building a business & sales career",
    img:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yjwj7m6yz3jxl.png",
  },
];
