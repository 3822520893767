import React from "react";
import "./styles.scss";

const HeroSection = () => {
  return (
    <>
      <div className="hero-sec-container-blp">
        <div className="first-div">
          <div className="duration-div">
            <img loading="lazy" src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yh2zsm5xrq2hk.png" />
            <div className="text text-1">
              <div className="header-text">Duration</div>
              <div className="normal-text1">
                5 Months <br></br> 600 hours
              </div>
            </div>
            <img
              className="pin1"
              src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yh2zsm5yw471v.png"
            />
          </div>
          <div className="mode-div">
            <img loading="lazy" src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yh2zsm5xruzzb.png" />
            <div className="text text-2">
              <div className="header-text">Learning </div>
              <div className="normal-text2">
                Full-time<br></br> Online, Live
              </div>
            </div>
            <img
              className="pin2"
              loading="lazy"
              src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yh2zsm5ywkroa.png"
            />
          </div>
        </div>
        <div className="first-div">
          <div className="mode-div">
            <img loading="lazy" src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yh2zsm5xrvyqs.png" />
            <div className="text text-1">
              <div className="header-text">Projects</div>
              <div className="normal-text1  project-text-2">
                4 Live Projects <br></br> with real brands
              </div>
            </div>
          </div>
          <img
            className="pin3"
            src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yh2zsm5ywkroa.png"
          />
          <div className="mode-div">
            <img
              loading="lazy"
              src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yh2zsm5xrx761.png"
              className="outcome-icon"
            />
            <div className="text text-2">
              <div className="header-text outcome-head-text">Outcome</div>
              <div className="normal-text2">
                Jobs upto ₹18LPA +<br /> PGP INDUSTRYCreds<sup>TM</sup>
                <br />
                Certification
              </div>
            </div>
          </div>
          <img
            className="pin4"
            loading="lazy"
            src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yh2zsm5yw471v.png"
          />
        </div>
      </div>
    </>
  );
};

export default HeroSection;
