// import { StaticImage } from 'gatsby-plugin-image';
import React, { useState, useEffect } from "react";
import "./styles.scss";

const outcomes = () => {
  const roles = [
    "Sales Development Representative (SDR)",
    "Business Development Representative (BDR)",
    "Business Development Manager (BDM)",
    "Area Sales Executive",
    "Sales Executive",
    "Account Managers",
    "Sr Associate, Partnerships",
  ];
  const rolesMob = [
    "Sales Development Representative (SDR)",
    "Business Development Representative (BDR)",
    "Area Sales Executive",
    "Sales Executive",
    "Account Managers",
    "Sr Associate, Partnerships",
  ];

  const mobData = [
    {
      img:
        "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/e9d233fb-9c08-4517-8357-587282525862/events/banner-upload/14i86yo8tqm836bpyo.webp",
    },
    {
      img:
        "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6p1gh0o.png",
    },
    {
      img:
        "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6p1hkby.png",
    },
  ];
  const [imageSrc, setImageSrc] = useState(
    "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum831zkoo.webp"
  );

  useEffect(() => {
    const updateImageSrc = () => {
      const isMobile = window.innerWidth <= 768;

      setImageSrc(
        isMobile
          ? "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/e9d233fb-9c08-4517-8357-587282525862/events/banner-upload/14i86yo8tqm836lmi7.webp" // Mobile image
          : "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum831zkoo.webp" // Desktop image
      );
    };

    updateImageSrc(); // Set initial image source
    window.addEventListener("resize", updateImageSrc);

    return () => window.removeEventListener("resize", updateImageSrc);
  }, []);

  return (
    <div className="marketing-outcomes">
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ul8hdr.png"
        loading="lazy"
        className="pc-outcome-dots-right"
      />
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ul1dzp.png"
        loading="lazy"
        className="pc-outcome-dots-left"
      />

      <div className="basl-outcome-folds">
        <div id="sticky-card-1" className="sticky-card">
          <div className="sticky-header-outcome">
            <div class="index-bg" id="outcome-index-bg">
              04
            </div>
            <div className="outcomes-sub">OUTCOMES</div>
            <div className="outcome-desc">
              {" "}
              India’s only PGP with accountability of placements
            </div>
            <img
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png"
              className="outcome-undeline"
            />
          </div>
          <div
            className="Basl-step-card"
            style={{
              background: `url("https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum8314phq.webp")`,
            }}
          ></div>
        </div>

        <div id="sticky-card-2" className="sticky-card">
          <div
            className="Basl-step-card"
            style={{
              background: `url("https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum83172dr.webp")`,
            }}
          ></div>
        </div>
        <div id="sticky-card-3" className="sticky-card">
          <div
            className="Basl-step-card"
            style={{
              background: `url("https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum831bjkp.webp")`,
            }}
          >
            <div className="roles-names">
              {roles.map((role, index) => {
                return (
                  <div key={index} className="role-name">
                    {role}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* for mobile */}
      <div className="basl-outcome-folds-mobile">
        <div id={`sticky-card-1`} className="sticky-card">
          <div className="sticky-header-outcome-mobile">
            <div class="index-bg" id="outcome-index-bg">
              04
            </div>
            <div className="outcomes-sub">OUTCOMES</div>
            <div className="outcome-desc">
              {" "}
              India’s only PGP with accountability of placements
            </div>
            <img
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png"
              className="outcome-undeline"
            />
          </div>
          <div
            className="Basl-step-card-mobile"
            style={{
              background: `url("https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/e9d233fb-9c08-4517-8357-587282525862/events/banner-upload/14i86yo8tqm836bpyo.webp")`,
            }}
          ></div>
        </div>

        <div id={`sticky-card-2`} className="sticky-card">
          <div
            className="Basl-step-card-mobile"
            style={{
              background: `url("https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/e9d233fb-9c08-4517-8357-587282525862/events/banner-upload/14i86yo8tqm836f52z.webp")`,
            }}
          ></div>
        </div>
        <div id={`sticky-card-3`} className="sticky-card">
          <div
            className="Basl-step-card-mobile"
            style={{
              background: `url("https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/e9d233fb-9c08-4517-8357-587282525862/events/banner-upload/14i86yo8tqm836hygt.webp")`,
            }}
          >
            <div className="roles-names-mob">
              {rolesMob.map((role, index) => {
                return (
                  <div key={index} className="role-name-mob">
                    {role}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="chanceOfHigh">
        The better you do, the better your chances are of a higher CTC.
        <br />
        But guess what? It's not all up to you.
        <span className="yellow-outcome">
          {" "}
          If your job &lt; ₹ 7.5L, we refund 60% fee
        </span>
      </div>

      <img src={imageSrc} loading="lazy" className="graph-image" />

      {/* <img
        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y9sinm4v1vliu.png"
        loading="lazy"
        className="diary-image mobile-hide"
      /> */}
      {/* <img
        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y9sinm4v1oczs.png"
        loading="lazy"
        className="diary-image pc-hide"
      /> */}
      {/* <Notes /> */}
    </div>
  );
};

export default outcomes;
