import React from "react";
import "./styles.scss";
import Marquee from "react-fast-marquee";

const index = () => {
  return (
    <div className="cohotM">
      <div className="cohotM-head">
        Lifelong relationships with your cohort. <br />
        Open access to events across the country
      </div>

      <Marquee
        gradient={false}
        speed={67}
        pauseOnHover={false}
        className="miner-community"
        loop={0}
      >
        <img
          src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum8330tol.webp"
          loading="lazy"
          className="marqueImg"
        />
        <img
          src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum833204i.webp"
          loading="lazy"
          className="marqueImg"
        />
        <img
          src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum8333r3y.webp"
          loading="lazy"
          className="marqueImg"
        />
        <img
          src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum83373gx.webp"
          loading="lazy"
          className="marqueImg"
        />
        <img
          src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum833bszx.webp"
          loading="lazy"
          className="marqueImg"
        />
        <img
          src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum833e5vk.webp"
          loading="lazy"
          className="marqueImg"
        />
        <img
          src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum833fnvh.webp"
          loading="lazy"
          className="marqueImg"
        />
        <img
          src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum833h85c.webp"
          loading="lazy"
          className="marqueImg"
        />
        <img
          src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum833ipe8.webp"
          loading="lazy"
          className="marqueImg"
        />
        <img
          src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum833jvr6.webp"
          loading="lazy"
          className="marqueImg"
        />
      </Marquee>
      <img
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/e9d233fb-9c08-4517-8357-587282525862/events/banner-upload/14i86yoa2pm8391snl.webp"
        alt="doted-line"
        loading="lazy"
        className="cohot-masonary-img"
      />
    </div>
  );
};

export default index;
