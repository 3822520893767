const newsImages = [
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82qfnk6.webp",
    url:
      "https://www.adgully.com/kraftshala-partners-with-nestle-to-inspire-people-to-pursue-sales-careers-145028.html",
    alt: "Adgully",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82qhahq.webp",
    url:
      "https://businessnewsthisweek.com/business/kraftshala-launches-pioneering-pg-program-in-sales-and-business-leadership-in-collaboration-with-industry/",
    alt: "Business-news",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g2hrtq.png",
    url:
      "https://www.mediainfoline.com/artnculture/kraftshala-partners-with-nestle-to-inspire-people-to-pursue-sales-careers",
    alt: "mi",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g2iw4a.png",
    url:
      "https://www.indiatoday.in/educationtoday/featurephilia/story/how-is-indias-outdated-exam-obsession-affecting-the-future-of-its-students-2639239-2024-11-26",
    alt: "india-today",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82qjgpx.webp",
    url:
      "https://hr.economictimes.indiatimes.com/news/hrtech/payroll-and-benefits/evaluating-real-compensation-of-a-job-offer-beyond-salary/113480873",
    alt: "ET-Hr",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g2s95h.png",
    url:
      "https://www.mid-day.com/buzz/article/behind-the-labels-what-lies-beneath-indias-education-promises-3282",
    alt: "mid-day",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82qlifi.webp",
    url:
      "https://drive.google.com/file/d/1GafXyLOhAbTlSk79_3tEEBwEqzKrJXnL/view?usp=drive_link",
    alt: "the-sunday",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g2xai1.png",

    url:
      "https://indianexpress.com/article/education/cat-2024-results-not-as-expected-alternative-routes-9742395/",
    alt: "the-indian-express",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g31wsz.png",
    url:
      "https://bwpeople.in/article/digital-marketing-hiring-growing-at-the-entry-level-kraftshalas-report-542936",
    alt: "bw-people",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g338ts.png",
    url:
      "https://www.hindustantimes.com/education/features/the-fastest-way-to-build-your-digital-marketing-career-in-2024-101729239361803.html",
    alt: "Hindustan-times",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g5037f.png",
    url:
      "https://mediabrief.com/kraftshala-partners-with-hul-for-season-xvi-of-l-i-m-e-for-training-b-school-students/",
    alt: "media-brief",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g387kv.png",
    url:
      "https://www.livemint.com/industry/campus-placements-recruiters-iits-candidates-jobs-students-interviews-11733201256889.html",
    alt: "mint",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g3aird.png",

    url:
      "https://www.afaqs.com/companies/kraftshala-announces-free-training-sessions-for-professionals-and-b-school-students",
    alt: "afaq",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g3cbwx.png",
    url: "https://www.youtube.com/watch?v=rYchpbMMJr0",
    alt: "NewsX",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g3eomz.png",
    url:
      "https://www.oneindia.com/partner-content/shifting-paradigms-interviews-in-2024-versus-a-decade-ago-3853361.html",
    alt: "One-India",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82qrx8d.webp",
    url:
      "https://www.ptinews.com/press-release/Kraftshala-partners-with-HUL-for-Season-XVI-of-L-I-M-E-for-training-B-School-students/1770262",
    alt: "press-trust",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g3om4j.png",
    url:
      "https://www.socialsamosa.com/report/only-7-digital-marketing-roles-2024-fully-remote-8550268",
    alt: "social-samosa",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g3q4xp.png",
    url:
      "https://www.business-standard.com/amp/budget/news/budget-to-plug-skill-gap-boost-employment-creation-hr-ed-tech-industry-124072301280_1.html",
    alt: "businees-standards",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g3s9m2.png",
    url:
      "https://timesofindia.indiatimes.com/life-style/parenting/moments/how-academic-pressure-affects-the-mental-well-being-of-aspirants/articleshowprint/115623082.cms",
    alt: "TOI",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82qypu6.webp",
    url:
      "https://www.theweek.in/wire-updates/business/2024/08/27/dcm59-kraftshala.html",
    alt: "The-week",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82r08as.webp",
    url:
      "https://bestmediainfo.com/mediainfo/advertising/kraftshala-launches-the-leadership-catalyst-series-for-mid-level-media-managers-4482387",
    alt: "best-media-info",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g43poc.png",
    url:
      "https://www.bizzbuzz.news/bizz-talk/this-online-skilling-platform-addresses-issue-of-employability-for-marketing-jobs-1329159",
    alt: "bizz buzz",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82r25t6.webp",
    url:
      "https://www.campaignindia.in/article/agencies-dominate-60-of-digital-marketing-openings-in-india-report/500040",
    alt: "campaign-india",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82r3y71.webp",
    url:
      "https://economictimes.indiatimes.com/jobs/graduates-helping-students-with-campus-placement/articleshow/61192043.cms?from=mdr",
    alt: "Economic-time",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82r5atu.webp",
    url:
      "https://www.socialsamosa.com/report/only-7-digital-marketing-roles-2024-fully-remote-8550268",
    alt: "people-matters",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82r6unj.webp",
    url:
      "https://www.impactonnet.com/more-from-impact/kraftshala-launches-indias-first-digital-marketing-and-english-course-9412.html",
    alt: "impact",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g4cz3n.png",
    url:
      "https://indiantelevision.com/mam/digital/kraftshala%E2%80%99s-2024-digital-marketing-hiring-trends%3A-skilled-entry-level-jobs-in-demand-241224",
    alt: "indian-television",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g4erlw.png",
    url:
      "https://www.ndtv.com/education/hindi-learners-can-benefit-from-these-new-age-courses-in-native-language-5675596",
    alt: "ndtv",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g4grl2.png",
    url:
      "https://hindi.news18.com/news/career/education-how-education-system-in-india-needs-to-be-changed-in-ai-times-8928823.html",
    alt: "news18",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82rbl4f.webp",
    url:
      "https://www.storyboard18.com/amp/special-coverage/social-media-jobs-on-the-rise-240000-active-talent-and-growing-40169.htm",
    alt: "story-board 18",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g4l8zy.png",
    url:
      "https://www.educationtimes.com/article/campus-beat-college-life/99736539/portal-exclusive-why-it-is-essential-to-have-hindi-language-courses-for-professional-development",
    alt: "The TOI-ET",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yich6m6g4uu0c.png",
    url:
      " https://www.telegraphindia.com/edugraph/news/headline-kraftshala-expands-its-marketing-launchpad-programmes-adds-new-course-in-digital-media/cid/1841718",
    alt: "telegraph-india",
  },
  {
    src:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum82rcxcr.webp",
    url:
      "https://www.educationtimes.com/article/campus-beat-college-life/99736539/portal-exclusive-why-it-is-essential-to-have-hindi-language-courses-for-professional-development",
    alt: "Education-times",
  },
  // Add more objects for the remaining 18 images
];

export default newsImages;
