export const admission = [
  {
    title: "Online Application",
    des:
      "Fill up your details, your professional goals and a reflection on how this program can help you get there",
    bg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yjwj7m6z81u9i.png",
    dotBg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yjwj7m6zalaw7.png",
  },
  {
    title: "Alignment Call",
    des:
      "Speak to your Program Counselor. The call allows us to understand your goals and whether Kraftshala would be a good fit",
    bg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yjwj7m6z838dt.png",
    dotBg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yjwj7m6zalaw7.png",
  },
  {
    title: "1-1 Interview ",
    des:
      "You next meet the Program Team. You’re assessed on problem solving, ownership and learning agility. ",
    bg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yjwj7m6z84jxx.png",
    dotBg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yjwj7m6zalaw7.png",
  },
  {
    title: "Decision",
    des:
      "We roll out the offers along with the scholarship decisions. If you’re offered, you start your enrollment process and join the ride :)",
    bg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yjwj7m6z85qc7.png",
    dotBg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yjwj7m6zalaw7.png",
  },
];

export const mobileSliderData = [
  {
    titlemob: "Online Application",
    desmob:
      "Fill up your details, your professional goals and a reflection on how this program can help you get there",
    mobBg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ykmm6m71lods8.png",
  },
  {
    titlemob: "Alignment Call",
    desmob:
      "Speak to your Program Counselor. The call allows us to understand your goals and whether Kraftshala would be a good fit",
    mobBg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ykmm6m71lq7wh.png",
  },
  {
    titlemob: "1-1 Interview ",
    desmob:
      "You next meet the Program Team. You’re assessed on problem solving, ownership and learning agility. ",
    mobBg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ykmm6m71lr9ik.png",
  },
  {
    titlemob: "Decision",
    desmob:
      "We roll out the offers along with the scholarship decisions. If you’re offered, you start your enrollment process and join the ride :)",
    mobBg:
      "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86ykmm6m73ar20r.png",
  },
];
