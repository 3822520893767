import React from "react";
import "./styles.scss";

const ScolarshipSub = () => {
  return (
    <div className="ScolarshipSub">
      <div className="scholar-sub-card">
        <div className="sub-card-head">4 Terms</div>
        <div className="sub-card-detail">4-5 weeks each</div>
      </div>
      <div className="scholar-sub-card">
        <div className="sub-card-head">800+</div>
        <div className="sub-card-detail">Hours</div>
      </div>
      <div className="scholar-sub-card">
        <div className="sub-card-head">4</div>
        <div className="sub-card-detail">LIVE projects</div>
      </div>
      <div className="scholar-sub-card">
        <div className="sub-card-head">Rs 17.2 L</div>
        <div className="sub-card-detail">Highest CTC</div>
      </div>
      <div className="scholar-sub-card">
        <div className="sub-card-head">50+</div>
        <div className="sub-card-detail">CXOs and Leaders</div>
      </div>
      <div className="scholar-sub-card">
        <div className="sub-card-head">80+</div>
        <div className="sub-card-detail">Hours of career prep</div>
      </div>
    </div>
  );
};

export default ScolarshipSub;
