import React from "react";

const Pledge = () => {
  return (
    <div className="pgp-pledge">
      <div className="pgp-pledge-lt">
        <div className="pgp-course">
          PGP in Sales and Business Leadership{" "}
          <span className="mob-hide">has comes with</span>
        </div>
        <div className="pledge-title">
          Kraftshala’s Placement Accountability Pledge
        </div>
        <div className="pledge-desc">
          If your job pays &lt; ₹7.5L, you get a 60% refund
        </div>
      </div>
      <div className="pgp-pledge-rt">
        <img
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1ulhr82.png"
          className="pledge-img pc-hide"
          loading="lazy"
        />
        <img
          src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yo1sum832ny11.webp"
          className="pledge-img mobile-hide"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default Pledge;
