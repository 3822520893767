import React from "react";
import "./styles.scss";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const GraphsMob = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div className="graphs pc-flex-none" ref={ref}>
      {inView && (
        <>
          <motion.img
            variants={{
              hidden: { opacity: 0, y: 70 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.55, delay: 0.55 }}
            src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6kdjp4f.png"
            className="graph-img"
          />
          <motion.img
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: { opacity: 1, y: -30 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.55, delay: 0.25 }}
            src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6kdkwt2.png"
            className="graph-img"
          />
          <motion.img
            variants={{
              hidden: { opacity: 0, y: 70 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.6, delay: 0.65 }}
            src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6kdllwi.png"
            className="graph-img"
          />
          <motion.img
            variants={{
              hidden: { opacity: 0, y: 30 },
              visible: { opacity: 1, y: -45 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.6, delay: 0.3 }}
            src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yiylkm6kdmhs2.png"
            className="graph-img"
          />
        </>
      )}
    </div>
  );
};

export default GraphsMob;
