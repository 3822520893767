import React, { useState } from "react";
import "./styles.scss";
import { cardData } from "./cardData";

const index = () => {
  const [active, setActive] = useState(1);
  const [Selected, setSelected] = useState(0);

  function changeTab(index) {
    setActive(index);
    setSelected(0);
  }

  function openCard(index) {
    if (index === Selected) {
      setSelected("");
    } else setSelected(index);
  }

  return (
    <div className="basl-journey">
      <div className="journey-head mobile-hide">
        Kraftshala’s journey is an intense experience -{" "}
      </div>
      <div className="journey-desc mobile-hide">
        Years of insights are packed into an 5 month program to deliver better
        results, faster.
      </div>

      <div className="journey-desc pc-hide">
        <span className="journey-head">
          Kraftshala is an intense experience{" "}
        </span>
        Years of insights are packed into a <br />5 month PG program to <br />{" "}
        deliver  <b>better results, faster.</b>
      </div>
      <div className="tab-row mobile-flex-hide">
        <div className="left-content">
          Learning happens through <br />
          Cases, Projects, Coaching, Workshops and 1-1 interviews
        </div>
        <div className="right-content position-relative w-100 mobile-hide">
          <div className="basl-j-table">
            <div className="tab-label">
              Case-Based <br /> Learning{" "}
            </div>
            <div className="w-100">
              <div className="table-wrapper">
                <div className="journey-table">
                  <div className="tbody">
                    <div className="tcol">
                      <div className="tr">
                        <div className="colm-ylo-sm newBg">
                          <b className="sbl-kraft-journey">Term 1 </b> <br />
                          Strategic Business Pillars
                        </div>
                        <div className="colm-ylo-sm">
                          Commercial <br /> Functions
                        </div>
                        <div className="colm-ylo-sm">
                          KPI/ Value Chain <br /> Understanding
                        </div>
                        <div className="colm-ylo-sm">
                          P&L <br /> Statement 
                        </div>
                        <div className="colm-ylo-sm">
                          Cash Flows <br /> and ROI
                        </div>
                        <div className="colm-ylo-sm"></div>
                      </div>

                      <div className="tr">
                        <div className="colm-ylo-lg newBg">
                          <b className="sbl-kraft-journey"> Term 2</b> <br />
                          Sales & <br /> Persuasion
                        </div>
                        <div className="colm-ylo-lg">Building Trust</div>
                        <div className="colm-ylo-lg">
                          Intelligence <br /> Gathering
                        </div>
                        <div className="colm-ylo-lg">Probing</div>
                        <div className="colm-ylo-lg">
                          Finding Value <br /> Prop
                        </div>
                        <div className="colm-ylo-lg">
                          Objection <br /> Handling
                        </div>
                      </div>

                      <div className="tr">
                        <div className="colm-ylo-md newBg">
                          <b className="sbl-kraft-journey">Term 3 </b> <br />
                          B2B Sales
                        </div>
                        <div className="colm-ylo-md">
                          B2B sales cycles, Revenue & Pricing models
                        </div>
                        <div className="colm-ylo-md">
                          Building ICPs & User Personas
                        </div>
                        <div className="colm-ylo-md">
                          Prospecting & Qualification
                        </div>
                        <div className="colm-ylo-md">Building Cadences</div>
                        <div className="colm-ylo-md">
                          Demos, Account Management
                        </div>
                      </div>

                      <div className="tr">
                        <div className="colm-ylo-md newBg">
                          <b className="sbl-kraft-journey">Term 4 </b>
                          <br />
                          FMCG Sales
                        </div>
                        <div className="colm-ylo-md">
                          FMCG Sales Levers, KPIs, Stakeholders
                        </div>
                        <div className="colm-ylo-md">Channel Management</div>
                        <div className="colm-ylo-md">Sales Levers & Margin</div>
                        <div className="colm-ylo-md">
                          Inventory & Pipeline Management
                        </div>
                        <div className="colm-ylo-md">
                          Ecom and Category Management
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="basl-j-table blue">
            <div className="tab-label"> Projects </div>
            <div className="w-100">
              <div className="table-wrapper">
                <div className="journey-table">
                  <div className="tbody">
                    <div className="tcol">
                      <div className="tr">
                        <div className="colm-ylo-md newBg-blue">
                          <b className="sbl-kraft-journey">All Terms </b> <br />
                          LIVE Projects
                        </div>
                        <div className="colm-ylo-md">Business Challenge</div>
                        <div className="colm-ylo-md">
                          Sales and Persuasion Project
                        </div>
                        <div className="colm-ylo-md blue-new-width">
                          B2B Sales Project - <br />
                          Driving Revenue
                        </div>
                        <div className="colm-ylo-md">FMCG Sales Project</div>
                      </div>

                      <div className="tr">
                        <div className="colm-ylo-lg newBg-blue">
                          <b className="sbl-kraft-journey"> All Terms </b>
                          <br />
                          Business Showcase
                        </div>
                        <div className="colm-ylo-lg">
                          Business Case Showcase
                        </div>
                        <div className="colm-ylo-lg">
                          Sales Process Showcase
                        </div>
                        <div className="colm-ylo-lg blue-new-width">
                          B2B Showcase
                        </div>
                        <div className="colm-ylo-lg">FMCG Showcase</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="basl-j-table red">
            <div className="tab-label">Workshops </div>
            <div className="w-100">
              <div className="table-wrapper">
                <div className="journey-table">
                  <div className="tbody">
                    <div className="tcol">
                      <div className="tr">
                        <div className="colm-ylo-sm newBg-red">
                          <b className="sbl-kraft-journey">All Terms </b>
                          <br />
                          Better Problem Solving (BPS)
                        </div>
                        <div className="colm-ylo-sm">BPS Mindset Building</div>
                        <div className="colm-ylo-sm">DIG DEEP Application</div>
                        <div className="colm-ylo-sm">
                          Excel as an Analysis tool
                        </div>
                        <div className="colm-ylo-sm">
                          Transferable consulting frameworks
                        </div>
                        <div className="colm-ylo-sm">BPS on Your Projects</div>
                      </div>

                      <div className="tr">
                        <div className="colm-ylo-lg newBg-red">
                          <b className="sbl-kraft-journey">All Terms</b> <br />
                          Digital and AI Literacy
                        </div>
                        <div className="colm-ylo-lg">
                          Intel Gathering & Data Enrichment
                        </div>
                        <div className="colm-ylo-lg">
                          Outbound & Calling tools
                        </div>
                        <div className="colm-ylo-lg">
                          Using & Leveraging CRMs
                        </div>
                        <div className="colm-ylo-lg">Automating workflows</div>
                        <div className="colm-ylo-lg">
                          Building a Tech Mindset
                        </div>
                      </div>
                      <div className="tr">
                        <div className="colm-ylo-lg newBg-red">
                          <b className="sbl-kraft-journey">All Terms</b> <br />
                          Human Skills
                        </div>
                        <div className="colm-ylo-lg">
                          Building your profile and narrative
                        </div>
                        <div className="colm-ylo-lg">
                          CV and Linkedin Optimization
                        </div>
                        <div className="colm-ylo-lg">
                          Voice and Accent training
                        </div>
                        <div className="colm-ylo-lg">
                          Building a Strong Work ethic
                        </div>
                        <div className="colm-ylo-lg">
                          Creating Decks like a consultant
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="basl-j-table green">
            <div className="tab-label">Evals </div>
            <div className="w-100">
              <div className="table-wrapper">
                <div className="journey-table">
                  <div className="tbody">
                    <div className="tcol">
                      <div className="tr">
                        <div className="colm-ylo-lg newBg-green green-height">
                          <b className="sbl-kraft-journey">All Terms</b> <br />{" "}
                          Interviews <br /> and Feedback
                        </div>
                        <div className="colm-ylo-lg green-height">
                          Baseline Assessment - Voice & Accent
                        </div>
                        <div className="colm-ylo-lg green-height">
                          Core Sales Presentation
                        </div>
                        <div className="colm-ylo-lg green-height">
                          B2B Mock Interview + Roadmap on improvements
                        </div>
                        <div className="colm-ylo-lg green-height">
                          FMCG Mock Interview + Roadmap on improvements
                        </div>
                        <div className="colm-ylo-lg green-height">
                          Behavioral Mock Interview + Roadmap on improvements
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="journey-mobile">
        <div className="journey-container">
          <div className="journey-tab">
            <div
              className={`term ${active === 1 && "active-term"}`}
              onClick={() => changeTab(1)}
            >
              Term 1
            </div>
            <div
              className={`term ${active === 2 && "active-term"}`}
              onClick={() => changeTab(2)}
            >
              Term 2
            </div>
            <div
              className={`term ${active === 3 && "active-term"}`}
              onClick={() => changeTab(3)}
            >
              Term 3
            </div>
            <div
              className={`term ${active === 4 && "active-term"}`}
              onClick={() => changeTab(4)}
            >
              Term 4
            </div>
            {/* <div
              className={`term ${active === 5 && "active-term"}`}
              onClick={() => changeTab(5)}
            >
              Term 5-8
            </div> */}
          </div>
        </div>
        <div className="journey-cards-container">
          {cardData &&
            cardData
              .filter((e) => e.term === active)
              .map((card, index) => {
                return (
                  <div
                    className="journey-card"
                    style={{ backgroundColor: card.bg }}
                    onClick={() => openCard(index)}
                  >
                    <div className="j-c-flex">
                      <div className="j-c-flex-head">{card.head}</div>

                      {index !== Selected ? (
                        <svg
                          class=""
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="10"
                          viewBox="0 0 21 10"
                          fill="none"
                        >
                          <path
                            d="M1 1L10.5 9L20 1"
                            stroke="#505050"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          class=""
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="10"
                          viewBox="0 0 20 10"
                          fill="none"
                        >
                          <path
                            d="M19 9L10 0.999999L1 9"
                            stroke="#505050"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      )}
                    </div>
                    {index === Selected && (
                      <>
                        <div className="j-c-flex-desc">
                          <div className="j-c-flex-sub-head">
                            {card.subhead}
                          </div>
                          <ul>
                            {card.desc.map((desc, index) => (
                              <li>{desc}</li>
                            ))}
                          </ul>

                          {/* <div className="disc"> {card.disc}</div> */}
                          <div className="j-c-flex-sub-head-2">
                            {card.subheadSecond}
                          </div>
                          <ul>
                            {card.descSecond?.map((desc, index) => (
                              <li>{desc}</li>
                            ))}
                          </ul>

                          <div className="j-c-flex-sub-head-3">
                            {card.subheadThird}
                          </div>
                          <ul>
                            {card.descThird?.map((desc, index) => (
                              <li>{desc}</li>
                            ))}
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default index;
