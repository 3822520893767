import React from "react";
import { data } from "./data";
// import { mobileData } from "./data";

const cardsMob = () => {
  return (
    <>
      <div className="project-card-container">
        {data.map((project, index) => (
          <div
            id={`sticky-card-${index + 1}`}
            key={index}
            className="sticky-card"
          >
            <div className="project-card">
              <div className="p-card">
                <div className="p-info">
                  <div
                    className="project-name"
                    style={{ color: project.color }}
                  >
                    {" "}
                    {project.project}
                  </div>
                  <div className="project-title"> {project.title}</div>
                  <div className="duration">
                    <span className="duration-icon">
                      <img src={project.durationIcon} alt="duration-Icon" />
                    </span>
                    Duration:{" "}
                    <span className="duration-val">{project.duration}</span>
                  </div>
                  <div className="goal">
                    <span className="goal-icon">
                      <img src={project.goalIcon} alt="goal-Icon" />
                    </span>
                    Goal: <span className="goal-val">{project.goalText}</span>
                  </div>
                  <div className="scope">
                    <span className="scope-icon">
                      {" "}
                      <img src={project.scopeIcon} alt="scope-Icon" />
                      Scope:{" "}
                    </span>

                    <span
                      className="scope-val"
                      dangerouslySetInnerHTML={{ __html: project.ScopeText }}
                    />
                  </div>
                  {/* <div
                  className="p-desc"
                  dangerouslySetInnerHTML={{ __html: project.desc }}
                /> */}
                  {/* <div className="p-disclaimer">{project.disclaimer}</div> */}
                </div>
                <div
                  className="p-img mobile-hide"
                  style={{ backgroundColor: "#F6F6F6" }}
                >
                  <div className="p-img-body">
                    <div
                      className="bullets-container"
                      style={{ position: "relative" }}
                    >
                      <img
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64w1ja6.png"
                        className="bullet-line"
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "10px",
                          // height: "100%",
                          zIndex: 0,
                        }}
                      />
                      {project.points.map((point) => {
                        return (
                          <div
                            className="bullets"
                            style={{ position: "relative", zIndex: 2 }}
                          >
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64vv8pu.png"
                              className="bulletImg"
                              alt="->"
                            />{" "}
                            {point}
                          </div>
                        );
                      })}
                    </div>
                    <div
                      className="p-highlight"
                      style={{ color: project.hColor }}
                    >
                      <img className="highlightImg" src={project.icon} />{" "}
                      {project.highlight}
                    </div>
                  </div>
                </div>
                <div
                  className="p-img pc-none"
                  style={{ backgroundImage: `url(${project.mobImg})` }}
                >
                  {/* <div className="p-img-body">
                  {project.points.map((point) => {
                    return (
                      <div className="bullets">
                        <img
                          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1u9hfxc.png"
                          className="bulletImg"
                          alt="->"
                        />{" "}
                        {point}
                      </div>
                    );
                  })}
                  <div
                    className="p-highlight"
                    style={{ color: project.hColor }}
                  >
                    <img className="highlightImg" src={project.icon} />{" "}
                    {project.highlight}
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* //////mob */}
      <div className="mobile-project-card-container">
        {data.map((project, index) => (
          <div
            id={`mobile-sticky-card-${index + 1}`}
            key={index}
            className="mobile-sticky-card"
          >
            <div className="mobile-project-card">
              <div className="mobile-p-card">
                <div className="mobile-p-info">
                  <div
                    className="mobile-project-name"
                    style={{ color: project.color }}
                  >
                    {" "}
                    {project.project}
                  </div>
                  <div className="mobile-project-title"> {project.title}</div>
                  <div className="mobile-duration">
                    <span className="mobile-duration-icon">
                      <img src={project.durationIcon} alt="duration-Icon" />
                    </span>
                    Duration:{" "}
                    <span className="mobile-duration-val">
                      {project.duration}
                    </span>
                  </div>
                  <div className="mobile-goal">
                    <span className="mobile-goal-icon">
                      <img src={project.goalIcon} alt="goal-Icon" />
                    </span>
                    Goal:{" "}
                    <span className="mobile-goal-val">
                      {project.goalTextMob}
                    </span>
                  </div>
                  <div className="mobile-scope">
                    <span className="mobile-scope-icon">
                      {" "}
                      <img src={project.scopeIcon} alt="scope-Icon" />
                      Scope:{" "}
                    </span>

                    <span
                      className="mobile-scope-val"
                      dangerouslySetInnerHTML={{ __html: project.ScopeTextMob }}
                    />
                  </div>
                </div>
                <div
                  className="mobile-p-img"
                  style={{ backgroundColor: "#F6F6F6" }}
                >
                  <div className="mobile-p-img-body">
                    <div
                      className="mobile-bullets-container"
                      style={{ position: "relative" }}
                    >
                      <img
                        src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64w1ja6.png"
                        className="mobile-bullet-line"
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "10px",
                          // height: "100%",
                          zIndex: 0,
                        }}
                      />
                      {project.pointsMob.map((point) => {
                        return (
                          <div
                            className="mobile-bullets"
                            style={{ position: "relative", zIndex: 2 }}
                          >
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86yhku2m64vv8pu.png"
                              className="mobile-bulletImg"
                              alt="->"
                            />{" "}
                            {point}
                          </div>
                        );
                      })}
                    </div>
                    <div
                      className="mobile-p-highlight"
                      style={{ color: project.hColor }}
                    >
                      <img className="mobile-highlightImg" src={project.icon} />{" "}
                      {project.highlight}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default cardsMob;
